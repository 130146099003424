
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import { calculator, home, peopleCircle, cog } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage},
  setup() {
    return {
      home,
      calculator,
      peopleCircle,
      cog
    }
  }
}
